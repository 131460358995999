
























































































import { Vue, Component, Emit } from "vue-property-decorator";
import { CustomInput, MakeDuplicate } from "@components";

interface AuthorizedEmails {
  serial: number;
  name: string;
  position: string;
  contact: string;
  email: string;
}

@Component({
  components: {
    CustomInput,
    MakeDuplicate,
  },
})
export default class AuthorizedEmail extends Vue {
  componentId = 0;
  authorizedEmail: AuthorizedEmails[] = [
    { serial: 0, name: "", position: "", contact: "", email: "" },
  ];

  _addNewCmponent() {
    this.componentId = this.componentId + 1;
    this.authorizedEmail.unshift({
      serial: this.componentId,
      name: "",
      contact: "",
      email: "",
      position: "",
    });
  }
  _removeComponent(id: number) {
    const newRepresentatives = this.authorizedEmail.filter(
      (rep) => rep.serial != id
    );
    this.authorizedEmail = newRepresentatives;
  }
  @Emit("getValue")
  created() {
    return 2;
  }
}
